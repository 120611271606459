import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import GTM from "@src/services/gtm"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { normalizeTitle, generateMeta } from "@src/utils/page-data"
import HeaderSimple from "@src/components/page-header-simple"
import { ImagePropTypes } from "@src/components/core-image"
import ResponsiveSection from "@src/components/container-responsive-section"
import HtmlContent from "@src/components/core-value-html"
import Button from "@src/components/core-button"
import SubscriptionForm from "@src/components/form-subscription"
import HighlightTiles from "@src/components/content-highlight-tiles"
import { PricePropTypes } from "@src/graphql-fragments/prices"

const FormContainer = styled(ResponsiveSection)`
  margin: 70px 0;
  padding: 40px 0;
  border: solid 2px ${props => props.theme.black};
  border-width: 2px 0;
  & .p1 {
    margin-bottom: 0.5em;
  }
  & .p3 {
    margin-bottom: 1.5em;
  }
`
const FooterBlock = styled.div`
  & > a {
    text-transform: uppercase;
    padding: 7px 16px 8px;
    & > .icon.icon-right {
      display: inline-block;
      margin-left: 10px;
    }
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    & > a {
      padding: 12px 24px;
      & > .icon.icon-right {
        margin-left: 16px;
      }
    }
  }
`

const orderByPromoTileSort = (a, b) => {
  const sortA = a && a.promoTile && a.promoTile.sort
  const sortB = b && b.promoTile && b.promoTile.sort
  if (sortA !== null && sortB === null) {
    return -1
  } else if (sortB !== null && sortA === null) {
    return 1
  } else if (sortA !== null && sortB !== null) {
    return sortA - sortB
  }
  return 0
}

const reduceToursIntoTiles = (result, tour) => {
  if (!tour || !tour.id) {
    return result
  }
  const tile = {
    tileType: "TOURS",
    id: `${tour.id}`,
    title: tour.title,
    button: {
      uri: "",
      text: "",
    },
    details: [],
  }
  if (tour.uri) {
    tile.button = {
      uri: tour.uri,
      text: "More Info +",
    }
  }
  if (tour.promoTile) {
    tile.content = tour.promoTile.text
    tile.image = tour.promoTile.image
  }
  if (tour.price) {
    tile.details.push({
      key: "tour_price_data",
      value: tour.price,
    })
  }
  if (tour.tourDuration?.days) {
    tile.details.push({
      key: "duration_days",
      value: `${tour.tourDuration.days}`,
    })
  }
  if (tour.tourType?.name) {
    tile.details.push({
      key: "tour_type",
      value: tour.tourType.name,
    })
  }
  result.push(tile)
  return result
}

const PageTemplate = ({ data, pageContext }) => {
  const {
    tenTours: {
      featuredContentGroup: {
        title,
        description,
        footerText,
        footerButtonText,
        footerButtonLink,
        images: { header: headerImage },
        seo: pageSeo,
        tours: { nodes: tours },
      },
    },
  } = data
  const { pageUri } = pageContext

  if (tours) {
    tours.sort(orderByPromoTileSort)
  }

  GTM.dataLayerPushPageInfo({
    template: "featured-sales",
  })
  return (
    <PrimaryLayout>
      <PageHelmet
        title={normalizeTitle(pageSeo.title)}
        meta={generateMeta(pageUri, { ...pageSeo, metaRobotsNoindex: "index" })}
      />
      <HeaderSimple image={headerImage} />
      <ResponsiveSection>
        <h1 className="fancy">{title}</h1>
        <HtmlContent
          html={description.replace(
            `<p><em>Offers coming soon! Please stay tuned.</em></p>`,
            ""
          )}
        />
      </ResponsiveSection>
      {tours && tours.length ? (
        <ResponsiveSection>
          <HighlightTiles
            id="featured-sales-tours"
            tiles={tours.reduce(reduceToursIntoTiles, [])}
          />
        </ResponsiveSection>
      ) : null}
      <FormContainer>
        <p className="p1">
          Be the first to get the best tour deals right to your inbox!
        </p>
        <p className="p3">
          Subscribe to our bi-weekly mailing list and receive all the tours that
          are on sale
        </p>
        <SubscriptionForm
          formName="sales-page"
          checkboxLabel="I agree to subscribe to the mailing list"
          buttonSize="full-width"
        />
      </FormContainer>
      <ResponsiveSection>
        <FooterBlock>
          <HtmlContent html={footerText} />
          {footerButtonLink ? (
            <Button to={footerButtonLink} iconRight="arrow-right">
              {footerButtonText || "View More Tours"}
            </Button>
          ) : null}
        </FooterBlock>
      </ResponsiveSection>
    </PrimaryLayout>
  )
}
PageTemplate.propTypes = {
  data: PropTypes.shape({
    tenTours: PropTypes.shape({
      featuredContentGroup: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        footerText: PropTypes.string,
        footerButtonText: PropTypes.string,
        footerButtonLink: PropTypes.string,
        images: PropTypes.shape({
          header: PropTypes.shape({ ...ImagePropTypes }),
        }),
        seo: PropTypes.shape({ ...PageSeoPropTypes }),
        tours: PropTypes.shape({
          nodes: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              title: PropTypes.string,
              uri: PropTypes.string,
              promoTile: PropTypes.shape({
                sort: PropTypes.number,
                text: PropTypes.string,
                image: PropTypes.shape({ ...ImagePropTypes }),
              }),
              price: PropTypes.shape({
                ...PricePropTypes,
              }),
              tourDuration: PropTypes.shape({
                days: PropTypes.number,
              }),
              tourType: PropTypes.shape({
                name: PropTypes.string,
              }),
            })
          ),
        }),
      }),
    }),
  }),
  pageContext: PropTypes.shape({
    pageUri: PropTypes.string,
  }),
}
export default PageTemplate
export const query = graphql`
  query ($id: ID!, $currency: TenToursGQL_CurrencyCodeEnum) {
    tenTours {
      featuredContentGroup(id: $id, idType: DATABASE_ID) {
        title: pageTitle
        description: descriptionRendered(format: HTML)
        footerText(format: HTML)
        footerButtonText
        footerButtonLink
        images {
          header {
            ...TenToursGQL_ImageSizesQuery
          }
        }
        seo {
          ...TenToursGQL_TermSEO
        }
        tours {
          nodes {
            id: databaseId
            title
            uri
            promoTile {
              sort
              text
              image {
                ...TenToursGQL_ImageSizesQuery
              }
            }
            price(currency: $currency) {
              ...PriceFragment
            }
            tourDuration {
              days
            }
            tourType {
              name
            }
          }
        }
      }
    }
  }
`
